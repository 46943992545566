import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const Page = () => {
    return (
        <Fragment>
            <CommonHero heading="Medical Director, Paul Laband, MD">
                <StaticImage
                    src="../../../assets/images/about-us/dr-paul/hero.jpg"
                    alt="Medical Director, Paul Laband, MD"
                    width={389}
                    height={482}
                    loading="eager"
                    transformOptions={{ cropFocus: 'north' }}
                />
            </CommonHero>

            <Article>
                <p>
                    Paul Laband, MD, is board-certified internist with almost 3 decades of
                    experience in primary and hospital-based care. He earned his medical degree at
                    Tufts University School of Medicine, and completed his internship, residency,
                    and chief residency in internal medicine at Maine Medical Center in Portland,
                    Maine. He joined St. Joseph Health in 2015, and in 2016 also began working as a
                    part-time hospice physician with Kindred Hospice. After several years of
                    experience as a hospice physician, he achieved the designation of Certified
                    Hospice Medical Director through the Hospice Medical Director Certification
                    Board. He also enjoys aesthetics and is familiar all aspects of medical spa
                    services. Helping patients feel their absolute best is his number one priority.
                </p>
                <StaticImage
                    src="../../../assets/images/about-us/dr-paul/certificate.jpg"
                    alt="certificate of Medical Director, Paul Laband, MD "
                    width={613}
                    height={460}
                    quality={100}
                    css={css`
                        margin: 32px auto 0;
                        display: block;
                        max-width: max-content;

                        @media (min-width: 1280px) {
                            margin-top: 64px;
                        }
                    `}
                />
            </Article>
        </Fragment>
    );
};

export default Page;
